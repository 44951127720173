// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_ASSET_PROPS,
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_WIZARD_STEP_FOOTER_PROPS,
} from './constants/card-settings-check-3d-wizard-step1.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Styles
import { CardSettingsCheck3dWizardStep1ComponentStyled } from './card-settings-check-3d-wizard-step1.component.styled';
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Translations
import { DESCRIPTION } from './translations/card-settings-check-3d-wizard-step1.translations';

const CardSettingsCheck3dWizardStep1Component = (): React.ReactElement => (
  <CardSettingsCheck3dWizardStep1ComponentStyled>
    <AssetComponent {...CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_ASSET_PROPS} />
    <ParagraphSecondaryComponentStyled>
      <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </ParagraphSecondaryComponentStyled>
    <WizardStepFooterComponent
      {...CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_WIZARD_STEP_FOOTER_PROPS}
      targets={FIRST_STEP_TARGETS}
    />
  </CardSettingsCheck3dWizardStep1ComponentStyled>
);

export default CardSettingsCheck3dWizardStep1Component;
