// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { CardSettingsChange3dStep1NewCodeComponent } from './components/new-code/card-settings-change-3d-step1-new-code.component';
import { CardSettingsChange3dStep1RepeatedCodeComponent } from './components/repeated-code/card-settings-change-3d-step1-repeated-code.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  CARD_SETTINGS_CHANGE_3D_STEP_1_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_PROPS,
} from './constants/card-settings-change-3d-step1.constants';
import { CARD_SETTINGS_CHANGE_3D_WIZARD_MODAL_CANCEL_PROPS } from '../../constants/card-settings-change-3d.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useCardSettingsChange3dStep1Hook } from './hooks/card-settings-change-3d-step1.hook';
// Styles
import { CardSettingsChange3dStep1ComponentStyled } from './card-settings-change-3d-step1.component.styled';
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION,
  MESSAGE_DESCRIPTION,
} from './translations/card-settings-change-3d-step1.translations';
// Utilities
import { change3DCodeStep1ButtonIsDisabled } from './utils/card-settings-change-3d-step1.utils';

const CardSettingsChange3DStep1Component = (): React.ReactElement => (
  <CardSettingsChange3dStep1ComponentStyled>
    <ParagraphComponent {...CARD_SETTINGS_CHANGE_3D_STEP_1_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <MessageComponent {...CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_PROPS}>
      <ParagraphSecondaryComponentStyled>
        <ParagraphComponent {...CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
        </ParagraphComponent>
      </ParagraphSecondaryComponentStyled>
    </MessageComponent>
    <CardSettingsChange3dStep1NewCodeComponent />
    <CardSettingsChange3dStep1RepeatedCodeComponent />
    <WizardStepFooterComponent
      {...CARD_SETTINGS_CHANGE_3D_WIZARD_MODAL_CANCEL_PROPS}
      rightButtonConfiguration={{
        disabled: change3DCodeStep1ButtonIsDisabled(useCardSettingsChange3dStep1Hook()),
      }}
      targets={FIRST_STEP_TARGETS}
    />
  </CardSettingsChange3dStep1ComponentStyled>
);

export { CardSettingsChange3DStep1Component };
